import React from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider, useSelector } from 'react-redux'
import { ReactReduxFirebaseProvider, isLoaded, useFirebase } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/performance'
import 'firebase/functions'
import { defaultRRFConfig } from 'defaultConfig'
import * as config from 'config'
import { setupPresences } from 'utils/presence'

import LoadingSpinner from 'components/LoadingSpinner'

// Initialize Firebase instance
firebase.initializeApp(config.firebase)
// Combine default and environment specific configs for react-redux-firebase
const rrfConfig = {
  ...defaultRRFConfig,
  ...{},
}

function AuthIsLoaded({ children }) {
  const firebase = useFirebase()
  const auth = useSelector(state => state.firebase.auth)
  const profile = useSelector(state => state.firebase.profile)

  if (!isLoaded(auth) || !isLoaded(profile)) {
    return (
      <div className="fullscreen-loading">
        <LoadingSpinner />
      </div>
    )
  }

  setupPresences(firebase)

  return children
}

function App({ routes, store }) {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider
        firebase={firebase}
        config={rrfConfig}
        dispatch={store.dispatch}
        createFirestoreInstance={createFirestoreInstance}
      >
        <Router>
          <AuthIsLoaded>{routes}</AuthIsLoaded>
        </Router>
      </ReactReduxFirebaseProvider>
    </Provider>
  )
}

App.propTypes = {
  routes: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
}

export default App
