export const env = "production";

export const firebase = {
  apiKey: "AIzaSyC57qCSQfLuT4NVaBXuxpo8zlLM0fCV-nQ",
  authDomain: "silicon-alley-prod.firebaseapp.com",
  databaseURL: "https://silicon-alley-prod.firebaseio.com",
  projectId: "silicon-alley-prod",
  storageBucket: "silicon-alley-prod.appspot.com",
};

export default { env, firebase }