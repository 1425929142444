import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { Layout, Row, Col } from 'antd'

const { Header } = Layout

function NavbarWithoutAuth({ profile, children }) {
  return (
    <Header className="ronin-header">
      <Row className="external-bar">
        <Col span={24}>
          <ul className="external-app-link">
            <li>
              <a href="https://www.siliconalley.co.th/">About Us</a>
            </li>
            <li>
              <a href="https://www.siliconalley.co.th/news">News</a>
            </li>
            <li>
              <a href="https://www.siliconalley.co.th/contact">Contact Us</a>
            </li>
          </ul>
          {profile}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <h1 className="brand">
            <Link to="/auctions">Silicon Alley</Link>
          </h1>
          {children}
        </Col>
      </Row>
    </Header>
  )
}

NavbarWithoutAuth.propTypes = {
  children: PropTypes.element,
}

export default NavbarWithoutAuth
