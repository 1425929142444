const CONSTANTS = {
  presence: 'sa-presence',
  sessions: 'sa-sessions',
  sessionKey: 'sessionKey',
}

function getAllSessions() {
  return JSON.parse(localStorage.getItem(CONSTANTS.sessionKey) || '[]') || []
}

function saveSession(key) {
  const sessionKeys = getAllSessions()
  sessionKeys.push(key)
  localStorage.setItem(CONSTANTS.sessionKey, JSON.stringify(sessionKeys))
}

function clearSession() {
  localStorage.setItem(CONSTANTS.sessionKey, '[]')
}

function getDependencies(firebase) {
  if (!firebase.auth().currentUser) return {}

  const authUid = firebase.auth().currentUser.uid
  const ref = firebase.database().ref()
  const amOnline = ref.child('.info/connected')
  const onlineRef = ref.child(CONSTANTS.presence).child(authUid)
  const sessionsRef = ref.child(CONSTANTS.sessions)

  return {
    authUid,
    amOnline,
    onlineRef,
    sessionsRef,
  }
}

async function clearLocalSession(firebase) {
  const { sessionsRef } = getDependencies(firebase)

  try {
    const sessionKeys = getAllSessions()

    await Promise.all(
      sessionKeys.map(async key => {
        await sessionsRef.child(key).remove()
      }),
    )

    clearSession()
  } catch (error) {
    console.log('cannot remove:', error)
  }
}

function setupPresences(firebase) {
  if (!firebase.database || !firebase.database.ServerValue) return

  const { authUid, amOnline, onlineRef, sessionsRef } = getDependencies(firebase)
  if (!authUid) return

  amOnline.on('value', snapShot => {
    if (!snapShot.val()) return
    if (sessionsRef) {
      const session = sessionsRef.push({
        startedAt: firebase.database.ServerValue.TIMESTAMP,
        user: authUid,
      })

      saveSession(session.key)
      session.onDisconnect().remove()
    }

    onlineRef.set(true)
  })
}

export { setupPresences, clearLocalSession }
