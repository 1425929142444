import React from 'react'
import { Menu, Dropdown, Icon } from 'antd'

function AccountMenu({ auth, logout }) {
  const menu = (
    <Menu className="account-dropdown-menu">
      {/* <Menu.Item key="0">{auth.displayName}</Menu.Item> */}
      {/* <Menu.Divider /> */}
      <Menu.Item key="1" onClick={logout}>
        <Icon type="export" />
        &nbsp; Sign out
      </Menu.Item>
    </Menu>
  )

  return (
    <div className="account-menu">
      <Dropdown overlay={menu} trigger={['click']}>
        <span className="account-dropdown">
          {auth.displayName}
          {/* <Avatar icon="user" size="small" /> */}
        </span>
      </Dropdown>
      {/* <IconButton
        aria-owns={anchorEl ? 'menu-appbar' : null}
        aria-haspopup="true"
        onClick={handleMenu}
        classes={{ root: classes.buttonRoot }}
      >
        <AccountCircle />
      </IconButton> */}
      {/* <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClose={closeAccountMenu}
      >
        <MenuItem onClick={goToAccount}>Account</MenuItem>
        <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
      </Menu> */}
    </div>
  )
}

export default AccountMenu
