import React from 'react'
import { Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

function Home() {
  const auth = useSelector(state => state.firebase.auth)
  if (auth.uid) {
    return <Redirect to="/auctions" />
  }

  return <Redirect to="/login" />
}

export default Home
