import React from 'react'
import PropTypes from 'prop-types'
import { Layout } from 'antd'

import Navbar from 'containers/Navbar'

const { Content, Footer } = Layout

function CoreLayout({ children }) {
  return (
    <Layout className="ronin-layout">
      <Navbar className="ronin-navbar" />
      <Content className="ronin-content">{children}</Content>
      <Footer className="ronin-footer">2020 © Silicon Alley</Footer>
    </Layout>
  )
}

CoreLayout.propTypes = {
  children: PropTypes.element.isRequired,
}

export default CoreLayout
