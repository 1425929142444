import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useFirebase, isLoaded, isEmpty } from 'react-redux-firebase'
import { actionTypes } from 'redux-firestore'
import { AUCTION_PATH } from 'constants/paths'
import AccountMenu from './AccountMenu'
import NavbarWithoutAuth from './NavbarWithoutAuth'
import { clearLocalSession } from 'utils/presence'

import './Navbar.scss'

const NavMenu = profile => {
  if (!profile) return null

  return (
    <ul className="internal-app-link">
      <li>
        <Link to="/auctions">Auctions</Link>
      </li>
      {profile.role_code === 'ADMIN' && (
        <>
          <li>
            <Link to="/users">Users</Link>
          </li>
          <li>
            <Link to="/orgs">Orgs</Link>
          </li>
          <li>/</li>
          <li>
            <Link to="/admin/user">+User</Link>
          </li>
          <li>
            <Link to="/admin/org">+Org</Link>
          </li>
        </>
      )}
    </ul>
  )
}

function Navbar() {
  const firebase = useFirebase()
  const dispatch = useDispatch()

  const auth = useSelector(state => state.firebase.auth)
  const authExists = isLoaded(auth) && !isEmpty(auth)
  const profile = useSelector(state => state.firebase.profile)

  const logout = () => {
    clearLocalSession(firebase).then(_ => {
      firebase.logout()
      dispatch({ type: actionTypes.CLEAR_DATA })
    })
  }

  return (
    <>
      {authExists ? (
        <NavbarWithoutAuth
          brandPath={AUCTION_PATH}
          profile={<AccountMenu auth={auth} logout={logout} />}
        >
          {NavMenu(isLoaded(profile) && !isEmpty(profile) && profile)}
        </NavbarWithoutAuth>
      ) : null}
    </>
  )
}

export default Navbar
